import idFromPathMethod from './id-from-path-method/index.js';
import replaceSpecialCharsWithUnderscore from './replace-special-chars-with-underscore.js';
export default function opId(operation, pathName) {
  let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  let {
    v2OperationIdCompatibilityMode
  } = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  if (!operation || typeof operation !== 'object') {
    return null;
  }
  const idWithoutWhitespace = (operation.operationId || '').replace(/\s/g, '');
  if (idWithoutWhitespace.length) {
    return replaceSpecialCharsWithUnderscore(operation.operationId);
  }
  return idFromPathMethod(pathName, method, {
    v2OperationIdCompatibilityMode
  });
}