'use strict';

var formatter = require('format');
var fault = create(Error);
module.exports = fault;
fault.eval = create(EvalError);
fault.range = create(RangeError);
fault.reference = create(ReferenceError);
fault.syntax = create(SyntaxError);
fault.type = create(TypeError);
fault.uri = create(URIError);
fault.create = create;

// Create a new `EConstructor`, with the formatted `format` as a first argument.
function create(EConstructor) {
  FormattedError.displayName = EConstructor.displayName || EConstructor.name;
  return FormattedError;
  function FormattedError(format) {
    if (format) {
      format = formatter.apply(null, arguments);
    }
    return new EConstructor(format);
  }
}