var Queue = function () {
  this.head = null;
  this.tail = null;
};
Queue.prototype = {
  add: function (item) {
    var entry = {
      item: item,
      next: null
    };
    var tail = this.tail;
    if (tail) tail.next = entry;else this.head = entry;
    this.tail = entry;
  },
  get: function () {
    var entry = this.head;
    if (entry) {
      var next = this.head = entry.next;
      if (next === null) this.tail = null;
      return entry.item;
    }
  }
};
module.exports = Queue;