var $ = require('../internals/export');
var isRegisteredSymbol = require('../internals/symbol-is-registered');

// `Symbol.isRegistered` method
// obsolete version of https://tc39.es/proposal-symbol-predicates/#sec-symbol-isregisteredsymbol
$({
  target: 'Symbol',
  stat: true,
  name: 'isRegisteredSymbol'
}, {
  isRegistered: isRegisteredSymbol
});