const types = require('./types');
exports.wordBoundary = () => ({
  type: types.POSITION,
  value: 'b'
});
exports.nonWordBoundary = () => ({
  type: types.POSITION,
  value: 'B'
});
exports.begin = () => ({
  type: types.POSITION,
  value: '^'
});
exports.end = () => ({
  type: types.POSITION,
  value: '$'
});