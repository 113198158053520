var isPrototypeOf = require('../../internals/object-is-prototype-of');
var arrayMethod = require('../array/virtual/includes');
var stringMethod = require('../string/virtual/includes');
var ArrayPrototype = Array.prototype;
var StringPrototype = String.prototype;
module.exports = function (it) {
  var own = it.includes;
  if (it === ArrayPrototype || isPrototypeOf(ArrayPrototype, it) && own === ArrayPrototype.includes) return arrayMethod;
  if (typeof it == 'string' || it === StringPrototype || isPrototypeOf(StringPrototype, it) && own === StringPrototype.includes) {
    return stringMethod;
  }
  return own;
};